interface Data {
  doc: Doc[];
}

interface Doc {
  data: DocData[];
}

interface DocData {
  _doc: string;
  _id: number;
  name: string; // 'Fotbal'
  realcategories: Category[];
}

interface Category {
  _id: number;
  name: string;
  cc: {}; // TODO: country info
  uniquetournaments: Record<string, Tournament>;
}

interface Tournament {
  _id: number;
  seasonid: number;
  name: string;
}

export interface TournamentData {
  tournament: Tournament;
  category: Category;
}

export async function loadTournaments(): Promise<TournamentData[]> {
  const response = await fetch(
    "https://stats.fn.sportradar.com/fortuna2/cs/Europe:Berlin/gismo/config_tree_mini/41/0"
  );
  const apiData: Data = await response.json();

  const result: TournamentData[] = [];

  for (const doc of apiData.doc) {
    for (const datum of doc.data) {
      if (includeSport(datum._id)) {
        for (const category of datum.realcategories) {
          for (const tournament of Object.values(category.uniquetournaments)) {
            result.push({ category, tournament });
          }
        }
      }
    }
  }

  return result;
}

function includeSport(sportId: number) {
  return sportId === 1; // Forbal
}

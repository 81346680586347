import { useQuery } from "@tanstack/react-query";
import React from "react";
import { loadTournaments } from "./queries/loadTournaments";

export function TournamentList() {
  const query = useQuery({
    queryKey: ["tournaments"],
    queryFn: loadTournaments,
  });
  return (
    <div>
      {query.data?.length} turnajů k analýze:
      <br />
      <br />
      {query.data?.map((data, index) => (
        <div key={index}>
          {data.category.name} - {data.tournament.name}
        </div>
      ))}
    </div>
  );
}

import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { TournamentList } from "./TournamentList";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App App-header">
        Ahoj tati. Tady je potenciální seznam fotbalových turnajů, které můžeme
        sledovat.
        <TournamentList />
      </div>
    </QueryClientProvider>
  );
}

export default App;
